import React from 'react';
import PropTypes from 'prop-types';
import Wrapper, { Main } from './styled';
import Header from './Header';
import Footer from './Footer';
import { SECONDARY } from '../../utils/colors';
import useUtm from '../../hooks/useUtm';

const Layout = ({ children, hasTracking, theme, location }) => {
  const utm = useUtm(location.search);
  return (
    <Wrapper background={theme === 'blue' ? SECONDARY : 'white'}>
      <Header theme={theme} hasTracking={hasTracking} utm={utm} />
      <Main>{children}</Main>
      <Footer utm={utm} />
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasTracking: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'blue']),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Layout.defaultProps = {
  hasTracking: true,
  theme: 'default',
  location: {
    search: '',
  },
};

export default Layout;
