import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import StyledSvg from './styled';

const Logo = memo(props => (
  <Link to="/">
    <StyledSvg {...props} />
  </Link>
));

Logo.propTypes = {
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  height: PropTypes.string,
  width: PropTypes.string,
};

Logo.defaultProps = {
  colors: {
    primary: '#474097',
    secondary: '#34BFCF',
  },
  height: '73px',
  width: '180px',
};

export default Logo;
