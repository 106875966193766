import styled from 'styled-components';

export const Subtitle = styled.h2`
  color: ${({ color }) => color || 'white'};
  font-size: 1rem;
  font-weight: normal;
  font-family: 'Open Sans', Arial, sans-serif;
`;

export default styled.section`
  background-color: ${({ background }) => background || 'transparent'};
  ${({ hasimage, paddingbottom, paddingtop }) =>
    !hasimage &&
    `
      padding-top: ${paddingtop || '100px'}; ;
      padding-bottom: ${paddingbottom || '250px'};
    `}
`;
