import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, NavItem, NavLink } from 'reactstrap';
import querystring from 'query-string';
import { Link } from 'gatsby';
import StyledNav from './styled';

const Nav = memo(({ isOpen, theme, utm }) => {
  const search =
    Object.keys(utm).length > 0 ? `?${querystring.stringify(utm)}` : '';

  return (
    <Collapse isOpen={isOpen} navbar>
      <StyledNav className="ml-auto" navbar theme={theme}>
        <NavItem>
          <Link className="nav-link" to="/comofunciona">
            Como Funciona
          </Link>
        </NavItem>
        <NavItem className="d-md-none">
          <Link className="nav-link" to="/rastrear">
            Rastrear Entrega
          </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/cadastro">
            Cadastre-se
          </Link>
        </NavItem>
        <NavItem>
          <NavLink
            href={`https://app.centraldofrete.com/login/${search}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Entrar
          </NavLink>
        </NavItem>
      </StyledNav>
    </Collapse>
  );
});

Nav.propTypes = {
  isOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'blue']),
  utm: PropTypes.objectOf(PropTypes.string),
};

Nav.defaultProps = {
  isOpen: false,
  theme: 'default',
  utm: {},
};

export default Nav;
