const isProduction = process.env.NODE_ENV === 'production';

export const PRE_QUOTATION_ADDRESS = 'pre_cotacao_endereco';
export const PRE_QUOTATION_VOLUMES = 'pre_cotacao_volumes';
export const PRE_QUOTATION_SIGNUP = 'pre_cotacao_cadastro';
export const SIGNUP = 'cadastro';
export const TRACKING_MENU = 'rastrear_pedido_menu';
export const TRACKING_JUMBOTRON = 'rastrear_pedido_jumbotron';

export const pushToDataLayer = data =>
  isProduction && (window.dataLayer || []).push({ ...data });

export const registerClick = data => pushToDataLayer(data);
