import styled from 'styled-components';
import { Button } from 'reactstrap';

export default styled(Button)`
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 0;
  margin-left: 1rem;
`;
