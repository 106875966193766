import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Container } from 'reactstrap';
import CollapseButton from './CollapseButton';
import Nav from '../Nav';
import Tracking from './Tracking';
import Logo from '../Logo';
import { PRIMARY, SECONDARY } from '../../../utils/colors';

const Header = memo(({ hasTracking, theme, utm }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggle = () => setIsOpenMenu(!isOpenMenu);

  return (
    <Navbar
      expand="md"
      sticky="top"
      style={{ backgroundColor: theme === 'blue' ? SECONDARY : 'white' }}
    >
      <Container>
        <Logo
          colors={{
            primary: theme === 'blue' ? 'white' : '#474097',
            secondary: theme === 'blue' ? PRIMARY : '#34BFCF',
          }}
        />
        <CollapseButton onClick={toggle} />
        <Nav isOpen={isOpenMenu} theme={theme} utm={utm} />
        {hasTracking && <Tracking />}
      </Container>
    </Navbar>
  );
});

Header.propTypes = {
  hasTracking: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'blue']),
  utm: PropTypes.objectOf(PropTypes.string),
};

Header.defaultProps = {
  hasTracking: true,
  theme: 'blue',
  utm: {},
};

export default Header;
