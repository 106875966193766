import React, { memo, useState } from 'react';
import { Form, Input } from 'reactstrap';
import { navigate } from 'gatsby';
import { Search } from 'styled-icons/material';
import Container from './StyledTracking';
import Button from './TrackingSubmit';
import { registerClick, TRACKING_MENU } from '../../../api/gtm';
import Icon from '../../Icon';

const Tracking = memo(() => {
  const [code, setCode] = useState();
  const onSubmit = event => {
    event.preventDefault();
    const formatedCode = (code || '').replace(/[^\w]/g, '');
    registerClick({ event: TRACKING_MENU, code });
    navigate(`/rastrear/?search=${formatedCode}`);
  };

  return (
    <Container className="d-none d-md-flex flex-column">
      <small className="text-white">Rastreio de carga</small>
      <Form inline onSubmit={onSubmit} method="get" action="/rastrear">
        <Input
          type="search"
          name="search"
          placeholder="Código de rastreio"
          required
          onChange={e => setCode(e.target.value)}
        />
        <Button type="submit" aria-label="Buscar" color="link">
          <Icon icon={Search} />
        </Button>
      </Form>
    </Container>
  );
});

export default Tracking;
