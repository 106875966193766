import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as COLORS from '../../utils/colors';

const SectionTitle = ({ children, color, component: Component, size }) => {
  const fontSize = size === 'small' ? '2rem' : undefined;
  return (
    <Component
      size={size}
      style={{
        color,
        fontWeight: '700',
        size: fontSize,
      }}
    >
      {children}
    </Component>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  color: PropTypes.oneOf(Object.values(COLORS)),
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

SectionTitle.defaultProps = {
  children: null,
  color: COLORS.SECONDARY,
  component: 'h2',
  size: 'medium',
};

export default memo(SectionTitle);
