import styled from 'styled-components';
import { NavbarToggler } from 'reactstrap';
import { PRIMARY } from '../../../utils/colors';

export default styled(NavbarToggler)`
  position: relative;
  :focus {
    outline: none;
  }
  ::-moz-focus-inner {
    border: 0;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: ${PRIMARY};
    left: 0;
  }

  &:before {
    top: 35%;
    width: 1rem;
  }

  &:after {
    top: calc(35% + 9px);
    width: 1.5rem;
  }
`;
