import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Container, Row } from 'reactstrap';
import Title from '../SectionTitle';
import Wrapper, { Subtitle } from './styled';
import { SECONDARY } from '../../utils/colors';

const Jumbotron = ({
  title,
  subtitle,
  Image,
  background,
  children,
  padding,
}) => (
  <Wrapper
    background={background}
    hasimage={Image ? 1 : 0}
    paddingbottom={padding.bottom}
    paddingtop={padding.top}
  >
    <Container>
      <Row
        className={classnames('row d-flex align-items-center', {
          'justify-content-center text-center': !Image,
          'justify-content-around': Boolean(Image),
        })}
      >
        <Col>
          <Title component="h1" color="white">
            {title}
          </Title>
          <Subtitle color={background === SECONDARY ? '#2DC1D0' : 'white'}>
            {subtitle}
          </Subtitle>
        </Col>
        {Boolean(Image) && (
          <Col className="d-none d-md-flex justify-content-end">
            <Image style={{ minWidth: '263px' }} />
          </Col>
        )}
      </Row>
    </Container>
    {children}
  </Wrapper>
);

Jumbotron.propTypes = {
  background: PropTypes.string,
  Image: PropTypes.func,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  padding: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
  }),
};

Jumbotron.defaultProps = {
  background: null,
  Image: null,
  subtitle: '',
  children: null,
  padding: {},
};

export default Jumbotron;
