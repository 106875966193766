import styled from 'styled-components';

const footerHeight = '230px';

export const Main = styled.main`
  background-color: white;
  padding-bottom: ${footerHeight};
  @media (max-width: 576px) {
    padding-bottom: 425px;
  }
`;

export default styled.div`
  background-color: ${({ background }) => background || 'white'};
  min-height: 100vh;
  position: relative;
`;
