import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import querystring from 'query-string';
import Logo from '../Logo';
import StyledFooter from './styled';
import FooterLinks from './FooterLinks';
import { getLinks } from './links';
import SocialLinks from './FooterSocialLinks';

const Footer = ({ utm }) => {
  const search =
    Object.keys(utm).length > 0 ? `?${querystring.stringify(utm)}` : '';
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col className="d-none d-md-block">
            <Logo
              colors={{ primary: '#fff', secondary: '#fff' }}
              width="155px"
              height="55px"
            />
          </Col>
          {getLinks(search).map((l, index) => (
            <FooterLinks links={l} key={index.toString()} />
          ))}
        </Row>
        <Row />

        <Row className="d-flex align-items-center">
          <Col>
            <small>
              Central do Frete &copy; Facilitando conexões -&nbsp;
              <a
                style={{
                  color: '#495057',
                  textDecoration: 'underline',
                }}
                href="/central-do-frete-cnpj-22531311000110"
              >
                CNPJ: 22.531.311/0001-10
              </a>
            </small>
          </Col>
          <Col xs="12" sm="5" lg="3">
            <SocialLinks />
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

Footer.propTypes = {
  utm: PropTypes.objectOf(PropTypes.string),
};

Footer.defaultProps = {
  utm: {},
};

export default Footer;
