import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Icon = memo(({ color, icon: Component, margin, size }) => (
  <Component style={{ width: size, height: size, color, margin }} />
));

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
  ]).isRequired,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
};

Icon.defaultProps = {
  color: 'white',
  size: '24px',
  margin: undefined,
};

export default Icon;
