import React from 'react';
import styled from 'styled-components';
import { socialLinks } from './links';
import { PRIMARY } from '../../../utils/colors';

const Link = styled.a`
  margin: 0.5rem;
  fill: white;

  &:hover {
    fill: ${PRIMARY};
  }
`;

const SocialLinks = () => (
  <nav className="d-flex flex-row justify-content-end">
    {socialLinks.map(({ Icon, path }) => (
      <Link href={path} key={path} target="_blank" rel="noopener noreferrer">
        <Icon />
      </Link>
    ))}
  </nav>
);

export default SocialLinks;
