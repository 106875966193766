import { reduce } from 'lodash';
import { useState, useEffect } from 'react';
import querystring from 'query-string';

const UTMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const parseUtmFromQuerystring = (search = '') => {
  const searchParsed = querystring.parse(
    search.charAt(0) === '?' ? search.substring(1) : search
  );
  const utmQueryParams = reduce(
    Object.keys(searchParsed),
    (acc, key) => ({
      ...acc,
      ...(UTMS.indexOf(key) !== -1 ? { [key]: searchParsed[key] } : {}),
    }),
    {}
  );

  return utmQueryParams;
};

export const getUtm = (search = '') => {
  const sessionUtm = window.sessionStorage.getItem('utm');
  if (sessionUtm) {
    return JSON.parse(sessionUtm);
  }

  const utmQueryParams = parseUtmFromQuerystring(search || '');

  if (Object.keys(utmQueryParams).length > 0) {
    sessionStorage.setItem('utm', JSON.stringify(utmQueryParams));
    return utmQueryParams;
  }

  return {};
};

const useUtm = (search = '') => {
  const [utm, setUtm] = useState({});

  useEffect(() => {
    setUtm(getUtm(search));
  }, [search]);

  return utm;
};

export default useUtm;
