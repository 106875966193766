import styled from 'styled-components';
import { Nav } from 'reactstrap';
import { PRIMARY, SECONDARY } from '../../../utils/colors';

export default styled(Nav)`
  margin: 1rem;
  .nav-item {
    padding: 0 0.5rem;
  }

  .nav-link {
    font-weight: bold;
    font-family: Roboto, Arial, sans-serif;

    &:hover {
      color: ${PRIMARY};
    }
  }

  ${({ theme }) => {
    switch (theme) {
      case 'blue':
        return `
          .nav-link {
            color: white;
          }
        `;
      default:
        return `
          .nav-link {
            color: ${SECONDARY};
          }
        `;
    }
  }}

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    border-radius: 0.3rem;
    background-color: ${PRIMARY};
    padding: 0.75rem;

    .nav-link:hover {
      color: white;
    }
  }
`;
