import styled from 'styled-components';
import { BACKGROUND_FOOTER, TEXT_FOOTER } from '../../../utils/colors';

export default styled.footer`
  background-color: ${BACKGROUND_FOOTER};
  color: ${TEXT_FOOTER};
  padding: 3rem 0 2rem;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
