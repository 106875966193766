import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Col } from 'reactstrap';
import { LINKS_FOOTER, PRIMARY } from '../../../utils/colors';

const Item = styled.li`
  color: ${LINKS_FOOTER};
  a {
    color: ${LINKS_FOOTER};

    &:hover {
      color: ${PRIMARY};
    }
  }
`;

const FooterLinks = memo(({ links }) => (
  <Col xs="12" md="3">
    <ul className="list-unstyled">
      {links.map(({ path, text }) => (
        <Item key={text}>
          {path.startsWith('http') ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={path} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          ) : (
            <Link to={path}>{text}</Link>
          )}
        </Item>
      ))}
    </ul>
  </Col>
));

FooterLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FooterLinks;
