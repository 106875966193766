import styled from 'styled-components';
import Svg from '../../../assets/svgs/logo.svg';

export default styled(Svg)`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  .st0 {
    fill: ${({ colors: { primary } }) => primary};
  }
  .st1 {
    fill: ${({ colors: { secondary } }) => secondary};
  }
`;
